import React from "react";
export const Check = () => (
  <svg
    width="49"
    height="33"
    viewBox="0 0 49 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.7071 17.2071C49.0976 16.8166 49.0976 16.1834 48.7071 15.7929L42.3431 9.42893C41.9526 9.03841 41.3195 9.03841 40.9289 9.42893C40.5384 9.81946 40.5384 10.4526 40.9289 10.8431L46.5858 16.5L40.9289 22.1569C40.5384 22.5474 40.5384 23.1805 40.9289 23.5711C41.3195 23.9616 41.9526 23.9616 42.3431 23.5711L48.7071 17.2071ZM14 17.5L48 17.5L48 15.5L14 15.5L14 17.5Z"
      fill="#E8C547"
    />
    <rect y="0.5" width="32" height="32" rx="16" fill="#E8C547" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4681 11.2207C23.7643 10.9248 24.2371 10.929 24.5231 11.2181L23.4681 11.2207ZM23.4681 11.2207L14.0003 20.6953L9.52938 16.2244C9.24677 15.9387 8.77178 15.9327 8.47129 16.23C8.18205 16.5162 8.17795 16.9891 8.47413 17.2853L13.4741 22.2853L13.477 22.2881C13.7631 22.5774 14.236 22.5815 14.5322 22.2853L24.0018 12.8157L23.9927 12.8066L24.5288 12.2762C24.8145 11.9936 24.8204 11.5186 24.5231 11.2181"
      fill="black"
    />
  </svg>
);
