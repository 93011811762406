export const Lock = () => (
  <svg
    width="49"
    height="36"
    viewBox="0 0 49 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.7071 16.7071C49.0976 16.3166 49.0976 15.6834 48.7071 15.2929L42.3431 8.92893C41.9526 8.53841 41.3195 8.53841 40.9289 8.92893C40.5384 9.31946 40.5384 9.95262 40.9289 10.3431L46.5858 16L40.9289 21.6569C40.5384 22.0474 40.5384 22.6805 40.9289 23.0711C41.3195 23.4616 41.9526 23.4616 42.3431 23.0711L48.7071 16.7071ZM14 17L48 17L48 15L14 15L14 17Z"
      fill="#00171F"
    />
    <rect width="4" height="20" transform="translate(14 16)" fill="#00171F" />
    <rect width="32" height="32" rx="16" fill="#00171F" />
    <path
      opacity="0.5"
      d="M13 12V14H19V12C19 10.3438 17.6562 9 16 9C14.3438 9 13 10.3438 13 12ZM12 14V12C12 9.79063 13.7906 8 16 8C18.2094 8 20 9.79063 20 12V14H20.5C21.8813 14 23 15.1188 23 16.5V21.5C23 22.8813 21.8813 24 20.5 24H11.5C10.1187 24 9 22.8813 9 21.5V16.5C9 15.1188 10.1187 14 11.5 14H12ZM10 16.5V21.5C10 22.3281 10.6719 23 11.5 23H20.5C21.3281 23 22 22.3281 22 21.5V16.5C22 15.6719 21.3281 15 20.5 15H11.5C10.6719 15 10 15.6719 10 16.5Z"
      fill="white"
    />
  </svg>
);
