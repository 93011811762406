import styled from "@emotion/styled";
import { Treatment } from "../../treatments";
import { InsightIcon } from "../../dialog/InsightIcon";
import { TrustIcon } from "./TrustIcon";
import { UptakeIcon } from "./UptakeIcon";
import { useSelector } from "react-redux";
import { getTags } from "../../selectors";
import React from "react";
import ReactPlayer from "react-player";
import { GPButton } from "../../Button";
import { useProtocolPlan } from "./useProtocolPlan";

import { useRecoilState } from "recoil";
import { earnedPointsAtom } from "./protocol-atom";

const ButtonBar = styled.div`
  label: button-bar;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const ModalOverlay = styled.div`
  label: modal-overlay;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DescriptionContainer = styled.div`
  label: description-container;
  background-color: #011e2d;
  color: #fff;
  width: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  z-index: 1001;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
  label: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  label: title;
  font-family: "Noto Sans";
  font-size: 24px;
  margin: 0;
`;

const Level = styled.div`
  label: level;
  background-color: #003153;
  padding: 4px 12px;
  border-radius: 4px;
`;

const Description = styled.p`
  label: description;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
`;

const Section = styled.div<{ layout?: string }>`
  label: section;
  display: flex;
  flex-direction: ${({ layout }) => layout || "column"};
  gap: 8px;
`;

const SectionTitle = styled.div`
  label: section-title;
  font-size: 14px;
  color: #60bae3;
  text-transform: uppercase;
`;

const BenefitsContainer = styled.div`
  label: benefits-container;
  display: flex;
  gap: 24px;
`;

const Benefit = styled.div`
  label: benefit;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TargetPatients = styled.div`
  label: target-patients;
  font-size: 16px;
`;

const PlanPointCost = styled.div`
  label: plan-point-cost;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Modal = styled.div`
  label: modal;
  position: relative;
  z-index: 1001;
  background-color: #315a78;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  min-width: 900px;
  padding: 10px;
  gap: 10px;
`;

const VideoContainer = styled.div`
  label: video-container;
  flex-grow: 1;
  background-color: #436985;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightColumn = styled.div`
  label: right-column;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface ProtocolDescriptionProps {
  treatment: Treatment | null;
  onClose: () => void;
  referenceMode?: boolean;
}

export const ProtocolDescription: React.FC<ProtocolDescriptionProps> = ({
  treatment,
  onClose,
  referenceMode = false,
}) => {
  const tags = useSelector(getTags);
  const { spentPoints, addTreatment, removeTreatment, isTreatmentActive } =
    useProtocolPlan();
  const [earnedPoints] = useRecoilState(earnedPointsAtom);
  
  const pointsLeft = earnedPoints - spentPoints;
  

  

  if (!treatment) return null;

  const canToggle = isTreatmentActive(treatment) || pointsLeft >= treatment.cost ;

  const toggleTreatment = () => {
    if (isTreatmentActive(treatment)) {
      removeTreatment(treatment);
    } else {
      addTreatment(treatment);
    }
  };
  const isActive = isTreatmentActive(treatment);

  return (
    <ModalOverlay>
      <Modal>
        <VideoContainer>
          <ReactPlayer
            width={500 * 0.75}
            height={440 * 0.75}
            controls={true}
            url={`/videos/${treatment.video}`}
          />
        </VideoContainer>
        <RightColumn>
          <DescriptionContainer onClick={(e) => e.stopPropagation()}>
            <Header>
              <Title>{treatment.name}</Title>
              <Level>Lvl {treatment.tier}</Level>
            </Header>

            <Description>{treatment.description}</Description>

            <Section layout="row" style={{ gap: "20px" }}>
              <Section style={{maxWidth: "60%"}}>
                <SectionTitle>Target patients</SectionTitle>
                <TargetPatients>
                  {treatment.tags?.map((tag) => tags[tag]).join(", ") || "All"}
                </TargetPatients>
              </Section>

              <Section>
                <SectionTitle>Plan point cost</SectionTitle>
                <PlanPointCost>{treatment.cost}</PlanPointCost>
              </Section>
            </Section>

            <Section>
              <SectionTitle>Benefits</SectionTitle>
              <BenefitsContainer>
                {treatment.buffs.Insight && (
                  <Benefit>
                    <InsightIcon />
                    Insight
                  </Benefit>
                )}
                {treatment.buffs.Trust && (
                  <Benefit>
                    <TrustIcon />
                    Trust
                  </Benefit>
                )}
                {treatment.buffs.Uptake && (
                  <Benefit>
                    <UptakeIcon />
                    Uptake
                  </Benefit>
                )}
              </BenefitsContainer>
            </Section>
          </DescriptionContainer>
          <ButtonBar>
            
            {referenceMode || <GPButton disabled={!canToggle} onClick={toggleTreatment}>{isActive ? "Remove from protocol" : "Add to protocol"}</GPButton>}

            <GPButton variant="secondary" onClick={onClose}>
              Close
            </GPButton>
            {/* <Button>Add to protocol</Button>
            <Button>Close</Button> */}
          </ButtonBar>
        </RightColumn>
      </Modal>
    </ModalOverlay>
  );
};
