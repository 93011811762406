export const UptakeIcon = () => (
  <svg
    width="20"
    height="27"
    viewBox="0 0 20 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
  <g clipPath="url(#clip0_1691_221)">
    <path
      d="M6.5 8.625H3.59023C3.40234 8.625 3.25 8.47266 3.25 8.28477C3.25 8.18828 3.29062 8.09688 3.36172 8.03086L9.75 2.31797L16.1383 8.03086C16.2094 8.09688 16.25 8.18828 16.25 8.28477C16.25 8.47266 16.0977 8.625 15.9098 8.625H13C12.1012 8.625 11.375 9.35117 11.375 10.25V19.1875C11.375 22.3309 8.83086 24.875 5.6875 24.875H2.4375C1.99063 24.875 1.625 24.5094 1.625 24.0625V22.4375C1.625 21.9906 1.99063 21.625 2.4375 21.625H5.6875C7.0332 21.625 8.125 20.5332 8.125 19.1875V10.25C8.125 9.35117 7.39883 8.625 6.5 8.625ZM10.4609 0.769141C10.2629 0.596484 10.0141 0.5 9.75 0.5C9.48594 0.5 9.23711 0.596484 9.03906 0.769141L2.28008 6.82227C1.86367 7.19297 1.625 7.72617 1.625 8.28477C1.625 9.37148 2.50352 10.25 3.59023 10.25H4.875H6.5V11.875V19.1875C6.5 19.6344 6.13438 20 5.6875 20H2.4375C1.0918 20 0 21.0918 0 22.4375V24.0625C0 25.4082 1.0918 26.5 2.4375 26.5H5.6875C9.72461 26.5 13 23.2246 13 19.1875V11.875V10.25H14.625H15.9098C16.9965 10.25 17.875 9.37148 17.875 8.28477C17.875 7.72617 17.6363 7.19297 17.2199 6.82227L10.4609 0.769141Z"
      fill="white"
    />
  </g>
  <defs>
    <clipPath id="clip0_1691_221">
      <rect
        width="19.5"
        height="26"
        fill="white"
        transform="translate(0 0.5)"
      />
    </clipPath>
    </defs>
  </svg>
);
