import styled from "@emotion/styled";
import React, { useState } from "react";
import geriactBackground from "../menu/MenuBackground.svg";
import { ProtocolMeter } from "./ProtocolMeter";
import { InsightIcon } from "../../dialog/InsightIcon";
import { ImpactIcon } from "./ImpactIcon";
import { TrustIcon } from "./TrustIcon";
import { UptakeIcon } from "./UptakeIcon";
import { CircleNumber } from "./CircleNumber";
import { PlanLibrary } from "./PlanLibrary";
import { PlanBoard } from "./PlanBoard";
import { earnedPointsAtom, protocolPlanAtom } from "./protocol-atom";
import { useRecoilState } from "recoil";
import { useProtocolPlan } from "./useProtocolPlan";
import { FiveM } from "../../five-ms";


export const MeterContainer = styled.div`
  label: meter-container;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  height: 73px;
  padding: 0px 35px;
`;

export const FullScreenDiv = styled.div`
  label: full-screen-div;
  color: #fff;
  width: 100%;
  height: 100vh;
  background-image: url(${geriactBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: stretch;
  align-items: stretch;
  background-color: #000;
  padding: 10px;
  overflow: hidden;
  position: relative;
`;



export const Header = styled.div`
  label: header;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 20px;
`;

export const ProtocolPlan: React.FC<{ referenceMode: boolean }> = ({
  referenceMode,
}) => {
  const [libraryOpen, setLibraryOpen] = useState(referenceMode);
  const [defaultFiveM, setDefaultFiveM] = useState<FiveM | null>(null);
  const [earnedPoints] = useRecoilState(earnedPointsAtom);
  const { spentPoints, insight, trust, uptake, impact } = useProtocolPlan();
  
  const openLibraryTo = (fiveM: FiveM) => {
    setLibraryOpen(true);
    setDefaultFiveM(fiveM);
  }
  

  return (
    <FullScreenDiv>      
      <Header>
        <h1>4Ms Protocol Plan: Wellness Visit</h1>
        <h4>Plan Points <CircleNumber number={earnedPoints - spentPoints} /></h4>

      </Header>

      {libraryOpen || <PlanBoard addScreening={openLibraryTo} /> }
      {libraryOpen && <PlanLibrary defaultFiveM={defaultFiveM} referenceMode={referenceMode} closeLibrary={() => setLibraryOpen(false)} />}

      
      {referenceMode || (
        <MeterContainer>
          <ProtocolMeter level={insight} label="Insight" icon={<InsightIcon />} />
          <ProtocolMeter level={trust} label="Trust" icon={<TrustIcon />} />
          <ProtocolMeter level={uptake} label="Uptake" icon={<UptakeIcon />} />
          <ProtocolMeter level={impact} label="Impact" icon={<ImpactIcon />} />
        </MeterContainer>
      )}

    </FullScreenDiv>
  );
};

export type PlanColumnStates = "open" | "closed" | "collumn";


export default ProtocolPlan;
