import React from "react";
import { Treatment } from "../../treatments";
import styled from "@emotion/styled";
import { CircleNumber } from "./CircleNumber";
import { GreenCheckCircle } from "./GreenCheckCircle";

const AssessmentContainer = styled.div<{ disabled: boolean; active: boolean }>`
  label: assessment;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 215px;
  gap: 5px;
  height: 105px;
  border: 1px solid #00070b;
  background-color: #001e2c;
  padding: 10px;
  flex-grow: 0;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  border-color: ${({ active }) => (active ? "#FFF" : "#00070b")};
  cursor: pointer;
  &:hover {
    background-color: #00171f;
  }
`;

interface ProtocolBoxProps {
  assessment: Treatment;
  disabled: boolean;
  active: boolean;
  level: number;
  referenceMode: boolean;
  onClick: () => void;
}

export const ProtocolBox: React.FC<ProtocolBoxProps> = ({
  referenceMode,
  assessment,
  disabled,
  active,
  level,
  onClick,
}) => {
  return (
    <AssessmentContainer disabled={disabled} active={active} onClick={onClick}>
      <AssessmentBody>
        <AssessmentName>
          <span>{assessment.name}</span>

          {active || <CircleNumber number={assessment.cost} /> }
          {active && <GreenCheckCircle />}
        </AssessmentName>
      </AssessmentBody>
      {referenceMode || <Levels level={level} />}
    </AssessmentContainer>
  );
};

const AssessmentBody = styled.div`
  label: assessment-body;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  flex-grow: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
`;

const AssessmentName = styled.div`
  label: assessment-name;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  flex-grow: 1;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    flex: 0 0 32px;
  }
`;

const LevelRow = styled.div`
  label: level-row;
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Levels: React.FC<{ level: number }> = ({ level }) => {
  return (
    <LevelRow>
      <LevelBox active={level > 0} />
      <LevelBox active={level > 1} />
      <LevelBox active={level > 2} />
    </LevelRow>
  );
};

const LevelBox = styled.div<{ active: boolean }>`
  label: level-box;
  min-width: 57px;
  height: 7px;
  flex-grow: 1;
  background-color: ${({ active }) => (active ? "#e8c547" : "#00171f")};
  border: 1px solid #e8c547;
`;
