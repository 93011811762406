export const CircleNumber = ({ number }: { number: number }) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      opacity="0.5"
      cx="16"
      cy="16.833"
      r="15"
      stroke="white"
      strokeWidth="2"
    />
    <text 
      x="50%"
      y="42%"
      fontSize="24"
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontFamily="Roboto"
      dy=".1em"
    >
      {number}
    </text>
  </svg>
);
