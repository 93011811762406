import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { loadSimTags, loadConstants } from "../constants";
import { areConstantsLoaded } from "../selectors";
import {
  treatmentsLoaded,
  simTagsLoaded,
  constantsLoaded,
  tagsLoaded,
} from "../simulation/simulation-slice";
import { loadTagDescriptions, loadTagLabels } from "../tags";
import { loadTreatments } from "../treatments";

export const useAirtableData = () => {
  const loaded = useSelector(areConstantsLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    console.info("Loading constants...");
    loadTreatments()
      .then((treatments) => {
        console.info("Treatments loaded");
        dispatch(treatmentsLoaded(treatments));
      })
      .catch((e) => {
        console.error(e);
      });

    loadSimTags().then((simTags) => {
      console.info("Sim tags loaded");
      dispatch(simTagsLoaded(simTags));
    });

    loadConstants()
      .then((constants) => {
        console.info("Constants loaded");
        dispatch(constantsLoaded(constants));
      })
      .catch((e) => {
        console.error(e);
      });

    Promise.all([loadTagDescriptions(), loadTagLabels()]).then(
      ([tagDescriptions, tagLabelMap]) => {
        console.info("Tag descriptions loaded");
        dispatch(tagsLoaded({ tags: tagDescriptions, labels: tagLabelMap }));
      }
    );
  }, [dispatch]);
  return loaded;
};
