import { Button } from "react-bootstrap";
import styled from "@emotion/styled";

const colorForVariant = ({ variant }: { variant?: string }) => {
  switch (variant) {
    case "danger":
      return "#df2b00";
    case "secondary":
      return "#0e2936";
    default:
      return "#e8c547";
  }
};

const textColorForVariant = ({ variant }: { variant?: string }) => {
  switch (variant) {
    case "danger":
      return "#df2b00";
    case "secondary":
      return "#fff";
    default:
      return "#00171f";
  }
};

export const GPButton = styled(Button)`
  color: ${textColorForVariant};
  background-color: ${colorForVariant};
  border-color: ${colorForVariant};
  border-radius: 0px;
  padding: 10px 20px;
  &:active,
  &:focus,
  &:hover {
    background-color: ${colorForVariant};
    border-color: ${colorForVariant};
  }

  &:disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #666;
  }

  &:active {
    transform: translate(0px, 2px);
    color: ${textColorForVariant};
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem ${colorForVariant}55;
    color: ${textColorForVariant};
  }
`;
