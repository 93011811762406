import React from "react";
import styled from "@emotion/styled";
import StartIcon from "./StartIcon";
import LockIcon from "./LockIcon";

interface LevelDetailsProps {
  title: string;
  description: string;
  image: string;
  onStart: () => void;
  locked: boolean;
  firstStep: boolean;
}

const LevelDetails: React.FC<LevelDetailsProps> = ({
  title,
  description,
  image,
  onStart,
  locked,
  firstStep,
}) => {
  return (
    <Container>
      <ImagePlaceholder>
        <img src={image} alt={title} />
      </ImagePlaceholder>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {locked || (
        <StartButton onClick={onStart}>
          <StartIcon />
          {firstStep ? "Start activity" : "Continue activity"}
        </StartButton>
      )}
      {locked && (
        <LockedMessage>
          <LockIcon />
          Locked
        </LockedMessage>
      )}
    </Container>
  );
};

const LockedMessage = styled.div`
  color: #fff;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  padding: 16px 24px;
  align-items: center;
  gap: 10px;
  background: rgba(0, 23, 31, 0.75);
`;

const Container = styled.div`
  background-color: #012d4d;
  padding: 20px;
  overflow: hidden;
  width: 544px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ImagePlaceholder = styled.div`
  background-color: #2c4a6f;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const Title = styled.h2`
  color: #ffffff;
  padding: 16px 16px 8px;
  margin: 16px 0px;
  background: rgba(0, 23, 31, 0.75);

  padding: 16px;
  font-size: 20px;
`;

const Description = styled.p`
  color: #b0c4de;

  background: rgba(0, 23, 31, 0.75);

  padding: 16px;
  margin-bottom: 16px;
  color: #fff;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StartButton = styled.button<{ disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? "#666" : "#e8c547")};
  color: ${({ disabled }) => (disabled ? "#999" : "#000000")};
  border: none;
  padding: 16px 20px;

  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  color: #00171f;

  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: #ffed4a;
  }
`;

export default LevelDetails;
