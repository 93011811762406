export const GreenCheckCircle = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1695_4403)">
      <path
        d="M16 32.833C20.2435 32.833 24.3131 31.1473 27.3137 28.1467C30.3143 25.1461 32 21.0765 32 16.833C32 12.5895 30.3143 8.51988 27.3137 5.5193C24.3131 2.51872 20.2435 0.833008 16 0.833008C11.7565 0.833008 7.68687 2.51872 4.68629 5.5193C1.68571 8.51988 0 12.5895 0 16.833C0 21.0765 1.68571 25.1461 4.68629 28.1467C7.68687 31.1473 11.7565 32.833 16 32.833ZM23.0625 13.8955L15.0625 21.8955C14.475 22.483 13.525 22.483 12.9438 21.8955L8.94375 17.8955C8.35625 17.308 8.35625 16.358 8.94375 15.7768C9.53125 15.1955 10.4812 15.1893 11.0625 15.7768L14 18.7143L20.9375 11.7705C21.525 11.183 22.475 11.183 23.0562 11.7705C23.6375 12.358 23.6437 13.308 23.0562 13.8893L23.0625 13.8955Z"
        fill="#4DA100"
      />
    </g>
    <defs>
      <clipPath id="clip0_1695_4403">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0 0.833008)"
        />
      </clipPath>
    </defs>
  </svg>
);
