export const PlusIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1257 13.3743C23.6054 13.3743 24 12.9797 24 12.5C24 12.0203 23.6054 11.6257 23.1257 11.6257H12.8743V1.37427C12.8743 0.894584 12.4797 0.5 12 0.5C11.5203 0.5 11.1257 0.894584 11.1257 1.37427V11.6257H0.874275C0.394584 11.6257 0 12.0203 0 12.5C0 12.9797 0.394584 13.3743 0.874275 13.3743H11.1257V23.6257C11.1257 24.1054 11.5203 24.5 12 24.5C12.4797 24.5 12.8743 24.1054 12.8743 23.6257V13.3743H23.1257Z"
      fill="white"
    />
  </svg>
);
