import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { saveGameState } from "./state/saveGameService";
import { gameStateAtom, initialGameState } from "./state/gameStateAtom";
import { useRecoilState } from "recoil";

export const DebugMenu: React.FC = () => {
  const [gameState, setGameState] = useRecoilState(gameStateAtom);
  const debugStage = gameState.currentStage;
  const debugStep = gameState.stages.find(
    (s) => s.id === debugStage
  )?.currentStep;
  const debugLabel = `Current Step: ${debugStage} - ${debugStep}`;

  return (
    <div
      style={{ position: "fixed", right: 0, top: 5, width: 30, zIndex: 1000 }}
    >
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          🪲
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <NavLink to="/debug">
            <Dropdown.Item href="/debug">
              Debug visualization screen
            </Dropdown.Item>
          </NavLink>

          <NavLink to="/debug/assessments">
            <Dropdown.Item href="/debug/assessments">
              Assessment Board
            </Dropdown.Item>
          </NavLink>


          <NavLink to="/dialog">
            <Dropdown.Item href="/dialog">Dialog Debugger</Dropdown.Item>
          </NavLink>

          <Dropdown.Divider />
          <Dropdown.Item>{debugLabel}</Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              setGameState(initialGameState);
              saveGameState(initialGameState);
            }}
          >
            Reset Game State
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
