import React, { useMemo, useState } from "react";
import { PlanColumnStates } from "./ProtocolPlan";
import { FiveM } from "../../five-ms";
import { FiveMNames } from "../../five-ms";
import styled from "@emotion/styled";
import { MattersIcon } from "../../dialog/MattersIcon";
import { MedicationIcon } from "../../dialog/MedicationIcon";
import { MentationIcon } from "../../dialog/MentationIcon";
import { MobilityIcon } from "../../dialog/MobilityIcon";
import { useSelector } from "react-redux";
import { getTreatments } from "../../selectors";
import { Treatment } from "../../treatments";
import { ProtocolDescription } from "./ProtocolDescription";
import { ProtocolBox } from "./ProtocolBox";
import { Check } from "./Check";
import { Lock } from "./Lock";
import { IconExit } from "../../emr/IconExit";
import { XIcon } from "./XIcon";
import { useProtocolPlan } from "./useProtocolPlan";

const ColumnContainer = styled.div`
  label: column-container;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 10px;
  gap: 10px;
  background-color: #003153;
  padding: 10px;
`;

const icons = {
  matters: <MattersIcon />,
  medication: <MedicationIcon />,
  mobility: <MobilityIcon />,
  mentation: <MentationIcon />,
};

export const PlanLibrary: React.FC<{
  referenceMode: boolean;
  closeLibrary: () => void;
  defaultFiveM: FiveM | null;
}> = ({ referenceMode, closeLibrary, defaultFiveM }) => {

  const initialIndex = defaultFiveM ? Object.keys(FiveMNames).indexOf(defaultFiveM) : 0;
  const [openColumn, setOpenColumn] = useState<number | null>(initialIndex);

  return (
    <ColumnContainer>
      {Object.keys(FiveMNames).map((key, index) => (
        <PlanColumn
          closeLibrary={closeLibrary}
          referenceMode={referenceMode}
          assessmentType={key as FiveM}
          key={index}
          icon={icons[key as FiveM]}
          state={openColumn === index ? "open" : "closed"}
          title={FiveMNames[key as FiveM]}
          toggleColumn={() => setOpenColumn(index)}
        />
      ))}
    </ColumnContainer>
  );
};

const Column = styled.div<{ state: PlanColumnStates }>`
  transition: all 0.3s ease-in-out;
  label: column;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${(props) =>
    props.state === "open" &&
    `
    width: 300px;
    flex-grow: 2;

  `}
  ${(props) =>
    props.state === "closed" &&
    `
    width: 64px;
    flex-grow: 0;
    flex-shrink: 0;
  `}
  ${(props) =>
    props.state === "collumn" &&
    `
    width: 200px;
  `}
`;

const AssessmentList = styled.div`
  label: assessment-list;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AssessmentRow = styled.div`
  label: assessment-row;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ColumnExitIcon = styled.div`
  label: column-exit-icon;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
`;

const ColumnIcon = styled.div<{ state: PlanColumnStates }>`
  label: column-icon;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-bottom: ${(props) =>
    props.state === "closed" ? "1px solid #00070b" : "none"};
`;

const LockColumn = styled.div`
  label: lock-column;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 64px;
  height: 64px;
  position: relative;
  svg {
    z-index: 1;
  }
`;

const ColumnContent = styled.div`
  label: column-content;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: 10px;
  overflow-x: auto;
`;

const ColumnLabelRow = styled.div<{ state: PlanColumnStates }>`
  label: column-label-row;
  display: flex;
  flex-direction: ${(props) => (props.state === "open" ? "row" : "column")};
  width: 100%;
  height: ${(props) => (props.state === "closed" ? "100%" : "64px")};
  cursor: pointer;
  background-color: #011e2d;
  border-bottom: ${(props) =>
    props.state === "open" ? "1px solid #00070b" : "none"};
`;

const ColumnLabel = styled.div<{ state: PlanColumnStates }>`
  label: column-label;
  display: flex;
  flex-direction: column;
  writing-mode: ${(props) =>
    props.state === "closed" ? "vertical-rl" : "horizontal-tb"};
  transform: ${(props) =>
    props.state === "closed" ? "rotate(180deg)" : "none"};

  margin-top: ${(props) => (props.state === "closed" ? "10px" : "0px")};

  width: 100%;

  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-align: left;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;

  border-radius: 4px;
`;

const LockBar = styled.div<{ filled: boolean; index: number }>`
  label: lock-bar;
  position: absolute;
  top: ${(props) => (props.index === 0 ? "20px" : "-26px")};
  bottom: ${(props) => (props.index === 2 ? "20px" : "-25px")};
  left: 15px;
  width: 3px;
  background-color: ${(props) => (props.filled ? "#e8c547" : "#00070b")};
  z-index: 0;
`;

const PlanColumn: React.FC<{
  referenceMode: boolean;
  assessmentType: FiveM;
  state: PlanColumnStates;
  title: string;
  toggleColumn: () => void;
  closeLibrary: () => void;
  icon: React.ReactNode;
}> = ({ referenceMode, state, title, assessmentType, toggleColumn, icon, closeLibrary }) => {
  const lockedTiers = [true && !referenceMode, false, false];
  const [openAssessment, setOpenAssessment] = useState<Treatment | null>(null);
  const { isTreatmentActive } = useProtocolPlan();  

  const assessments = useSelector(getTreatments);

  const showCloseIcon = !referenceMode && state === "open";

  const tiers = useMemo(
    () =>
      assessments
        .filter((t) => t.category === assessmentType)
        .reduce(
          (acc, t) => {
            acc[t.tier - 1].push(t);
            return acc;
          },
          [[], [], []] as Treatment[][]
        )
        .reverse(),
    [assessmentType, assessments]
  );

  return (
    <>
      <ProtocolDescription
        referenceMode={referenceMode}
        treatment={openAssessment}
        onClose={() => setOpenAssessment(null)}
      />
      <Column state={state}>
        <ColumnLabelRow state={state} onClick={toggleColumn}>
          <ColumnIcon state={state}>{icon}</ColumnIcon>
          <ColumnLabel state={state}>{title}</ColumnLabel>
          {showCloseIcon && (
            <ColumnExitIcon onClick={closeLibrary}>
              <XIcon />
            </ColumnExitIcon>
          )}
        </ColumnLabelRow>
        {state === "open" && (
          <ColumnContent>
            <AssessmentList>
              {tiers
                .filter((t) => t.length > 0)
                .map((tier, index) => (
                  <AssessmentRow key={index}>
                    {referenceMode || (
                      <LockColumn>
                        <LockBar filled={!lockedTiers[index]} index={index} />
                        {lockedTiers[index] && <Lock />}
                        {!lockedTiers[index] && <Check />}
                      </LockColumn>
                    )}

                    {tier.map((assessment, jndex) => (
                      <ProtocolBox
                        key={`${index}-${jndex}`}
                        referenceMode={referenceMode}
                        assessment={assessment}
                        disabled={lockedTiers[index]}
                        active={!referenceMode && isTreatmentActive(assessment)}
                        level={index + 1}
                        onClick={() => setOpenAssessment(assessment)}
                      />
                    ))}
                  </AssessmentRow>
                ))}
            </AssessmentList>
          </ColumnContent>
        )}
      </Column>
    </>
  );
};
