//showProtocolPlan();

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ProtocolPlan } from "../game/protocol-plan/ProtocolPlan";
import { protocolPlanVisibleAtom } from "./protocolPlanAtom";
import { useRecoilState } from "recoil";

const ProtocolPlanContainer = styled.div({
  position: "fixed",
  top: 20,
  left: 500,
  right: 20,
  bottom: 120,

});

export const DialogProtocolPlan: React.FC<{
  onClose: (nextNode: string) => void;
}> = ({ onClose }) => {
  const [visible, setVisible] = useRecoilState(protocolPlanVisibleAtom);
  const [nextNode, setNextNode] = useState<string | null>(null);

  useEffect(() => {
    window.showProtocolPlan = (nextNode: string | null = null) => {
      setVisible(true);
      setNextNode(nextNode);
    };

    window.hideProtocolPlan = () => {
      setVisible(false);
    };
  }, []);

  const handleClose = () => {
    setVisible(false);
    nextNode && onClose(nextNode);
  };

  if (!visible) {
    return null;
  }

  return (
    <ProtocolPlanContainer>
      <ProtocolPlan referenceMode={false} />
    </ProtocolPlanContainer>
  );
};

declare global {
  interface Window {
    showProtocolPlan: (nextNode?: string | null) => void;
    hideProtocolPlan: () => void;
  }
}

