import React from 'react';
import ProtocolPlan from './ProtocolPlan';
import { useSearchParams } from 'react-router-dom';
import { useAirtableData } from '../useAirtableData';

export const ProtocolPlanDebug: React.FC = () => {
  const loaded = useAirtableData();
  
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  


  if (!loaded) return <div>Loading constants...</div>;
  return (
    <div className="debug-container">
        <ProtocolPlan referenceMode={mode === "reference"} />
    </div>
  );
};

export default ProtocolPlanDebug; 