import { useEffect } from "react";
import "firebaseui/dist/firebaseui.css";
import "tachyons";
import "./App.css";
import VisitScreen from "./VisitScreen";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { DebugSimulator } from "./debug/DebugSimulator";
import { Login } from "./Login";
import { Provider, useDispatch, useSelector } from "react-redux";

import { store } from "./root-store";

import { loadTreatments } from "./treatments";
import { loadConstants, loadSimTags } from "./constants";

import {
  treatmentsLoaded,
  constantsLoaded,
  tagsLoaded,
  simTagsLoaded,
} from "./simulation/simulation-slice";
import { areConstantsLoaded } from "./selectors";
import { Logout } from "./Logout";
import { StartScreen } from "./game/start/StartScreen";
import { GamePlay } from "./game/GamePlay";
import { DialogDebugger } from "./dialog/debug/DialogDebugger";
import { DebugMenu } from "./DebugMenu";
import { loadTagDescriptions, loadTagLabels } from "./tags";
import { DebugEMR } from "./dialog/debug/DebugEMR";
import { RecoilRoot } from "recoil";
import GameMenuScreen from "./game/menu/GameMenuScreen";

import { getAnalytics, logEvent } from "firebase/analytics";
import { UserReport } from "./report/UserReport";
import { AssessmentsScreenLoader } from "./game/add-assessment/AssessmentsScreen";
import ProtocolPlanDebug from "./game/protocol-plan/ProtocolPlanDebug";


const LoggedInRoutes = () => (
  <Login>
    <Routes>
      <Route path="/debug/emr" element={<DebugEMR />} />
      <Route path="/debug/protocol-plan" element={<ProtocolPlanDebug />} />
      <Route path="/debug" element={<DebugSimulator />} />
      <Route path="/debug/assessments" element={<AssessmentsScreenLoader />} />
      <Route path="/oldgame" element={<VisitScreen />} />
      <Route path="/menu" element={<GameMenuScreen />} />
      <Route path="/" element={<StartScreen />} />
      <Route path="/play/*" element={<GamePlay />} />
      <Route path="/dialog/*" element={<DialogDebugger />} />
      <Route path="/admin/user-report" element={<UserReport />} />
    </Routes>
  </Login>
);

const App = () => {
  useEffect(() => {
    const analytics = getAnalytics();

    const handleUncaughtException = (error) => {
      console.error("Uncaught exception:", error);
      logEvent(analytics, "uncaught_exception", {
        error_message: error.message,
        error_stack: error.stack,
      });
    };

    window.addEventListener("error", handleUncaughtException);

    return () => {
      window.removeEventListener("error", handleUncaughtException);
    };
  }, []);

  return (
    <RecoilRoot>
      <Provider store={store}>
        {/* <StaticLoader> */}
        <BrowserRouter>
          <Routes>
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<LoggedInRoutes />} />
          </Routes>
          <DebugMenu />
        </BrowserRouter>
        {/* </StaticLoader> */}
      </Provider>
    </RecoilRoot>
  );
};

export default App;
