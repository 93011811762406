export const XIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2812 25.6938C24.6688 26.0813 25.3062 26.0813 25.6938 25.6938C26.0813 25.3062 26.0813 24.6688 25.6938 24.2812L17.4125 16L25.6938 7.71875C26.0813 7.33125 26.0813 6.69375 25.6938 6.30625C25.3062 5.91875 24.6688 5.91875 24.2812 6.30625L16 14.5875L7.71875 6.30625C7.33125 5.91875 6.69375 5.91875 6.30625 6.30625C5.91875 6.69375 5.91875 7.33125 6.30625 7.71875L14.5875 16L6.30625 24.2812C5.91875 24.6688 5.91875 25.3062 6.30625 25.6938C6.69375 26.0813 7.33125 26.0813 7.71875 25.6938L16 17.4125L24.2812 25.6938Z"
      fill="white"
    />
  </svg>
);
