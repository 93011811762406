import React, { useState } from "react";
import { DialogDebugConfigDoc } from "./DialogDebugConfigDoc";
import { DialogDebugPreview } from "./DialogDebugPreview";
import { Route, Routes, useParams, useSearchParams } from "react-router-dom";
import { useAirtableData } from "../../game/useAirtableData";

export const DialogDebugger: React.FC<any> = () => {
  const [sheetID, setSheetID] = useState<string>("");
  useAirtableData();

  return (
    <div className="container-md">
      <h1>Dialog Debugger</h1>
      <Routes>
        <Route path="/:sheetId/*" element={<RoutedPreview />} />
        <Route
          path="/*"
          element={
            <DialogDebugConfigDoc sheetId={sheetID} setId={setSheetID} />
          }
        />
      </Routes>
    </div>
  );
};

const RoutedPreview: React.FC<any> = () => {
  const { sheetId } = useParams();
  const [searchParams] = useSearchParams();
  const show4MEmrTab = searchParams.get('show4MEmrTab') === 'true';  
  return <DialogDebugPreview sheetId={sheetId || ""} show4MEmrTab={show4MEmrTab || false} />;
};
