import { useRecoilState } from "recoil";
import { protocolPlanAtom } from "./protocol-atom";
import { Treatment } from "../../treatments";
import { useMemo } from "react";

export const useProtocolPlan = () => {
  const [activeTreatments, setActiveTreatments] =
    useRecoilState(protocolPlanAtom);

  const addTreatment = (treatment: Treatment) => {
    setActiveTreatments((prev: Treatment[]) => {
      return [...prev, treatment];
    });
  };

  const removeTreatment = (treatment: Treatment) => {
    setActiveTreatments((prev: Treatment[]) => {
      return prev.filter((t) => t.id !== treatment.id);
    });
  };

  const isTreatmentActive = (treatment: Treatment) => {
    return activeTreatments.some((t) => t.id === treatment.id);
  };

  const spentPoints = useMemo(
    () => activeTreatments.reduce((acc, t) => acc + t.cost, 0),
    [activeTreatments]
  );

  const trust = useMemo(
    () => activeTreatments.reduce((acc, t) => acc + (t.buffs.Trust || 0), 0),
    [activeTreatments]
  );

  const insight = useMemo(
    () => activeTreatments.reduce((acc, t) => acc + (t.buffs.Insight || 0), 0),
    [activeTreatments]
  );

  const uptake = useMemo(
    () => activeTreatments.reduce((acc, t) => acc + (t.buffs.Uptake || 0), 0),
    [activeTreatments]
  );

  const impact = useMemo(
    () => activeTreatments.reduce((acc, t) => acc + t.impact[0].healthy, 0),
    [activeTreatments]
  );

  return {
    activeTreatments,
    setActiveTreatments,
    addTreatment,
    removeTreatment,
    isTreatmentActive,
    spentPoints,
    trust,
    insight,
    uptake,
    impact,
  };
};
