import styled from "@emotion/styled";


interface ProtocolMeterProps {
  level: number;
  label: string;
  icon: React.ReactNode;
}

const MeterContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr 1fr;
  width: 25%;
  height: 40px;
  gap: 10px;
  align-items: center;

  .icon {
    grid-row: 1 / span 2;
    grid-column: 1;
    align-self: end;
  }

  .label {
    grid-row: 1;
    grid-column: 2;
    font-size: 12px;
    align-self: end;
  }

  .level {
    grid-row: 1 / span 2;
    grid-column: 3;
    align-self: end;
    margin-bottom: -5px;
  }
`;

const MeterBackground = styled.div`  width: 100%;
  height: 10px;
  background-color: #001d2c;
  grid-row: 2;
  grid-column: 2;
  font-size: 0.8em;
`;

const Meter = styled.div<{ level: number }>`
  width: ${({ level }) => level}%;
  height: 100%;
  height: 10px;
  background-color: #e8c547;
`;

export const ProtocolMeter: React.FC<ProtocolMeterProps> = ({ level, label, icon }) => {
  return (
    <MeterContainer>
      <div className="icon">{icon}</div>
      <div className="label">{label}</div>
      <MeterBackground>
        <Meter level={level} />
      </MeterBackground>
      <div className="level">{level}</div>
    </MeterContainer>
  );
};

