import styled from "@emotion/styled";
import { MattersIcon } from "../../dialog/MattersIcon";
import { MedicationIcon } from "../../dialog/MedicationIcon";
import { MentationIcon } from "../../dialog/MentationIcon";
import { MobilityIcon } from "../../dialog/MobilityIcon";
import { FiveM } from "../../five-ms";
import { FiveMNames } from "../../five-ms";
import { PlusIcon } from "./PlusIcon";
import React from "react";

import { useProtocolPlan } from "./useProtocolPlan";

const icons = {
  matters: <MattersIcon />,
  medication: <MedicationIcon />,
  mobility: <MobilityIcon />,
  mentation: <MentationIcon />,
};

const ColumnContainer = styled.div`
  label: column-container;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 10px;
  gap: 10px;
  padding: 10px;
`;

export const PlanBoard = ({ addScreening }: { addScreening: (defaultFiveM: FiveM) => void }) => {
  return (
    <ColumnContainer>
      {Object.keys(FiveMNames).map((key, index) => (
        <PlanColumn
          addScreening={addScreening}
          assessmentType={key as FiveM}
          key={index}
          icon={icons[key as FiveM]}
          title={FiveMNames[key as FiveM]}
        ></PlanColumn>
      ))}
    </ColumnContainer>
  );
};

const PlanColumn: React.FC<{
  assessmentType: FiveM;
  icon: React.ReactNode;
  title: string;
  addScreening: (defaultFiveM: FiveM) => void;
}> = ({ assessmentType, icon, title, addScreening }) => {
  const { activeTreatments } = useProtocolPlan();

  return (
    <Column>
      <ColumnHeader>
        {icon}
        <span>{title}</span>
      </ColumnHeader>
      <ColumnBody>
        {activeTreatments.filter((t) => t.category === assessmentType).map((t) => (
          <Treatment key={t.id}>{t.name}</Treatment>
        ))}
      </ColumnBody>
      <ColumnFooter onClick={() => addScreening(assessmentType)}>
        <PlusIcon /> Add screening
      </ColumnFooter>
    </Column>
  );
};

const Treatment = styled.div`
  background-color: #001c29;
  padding: 15px;
  
`;
const Column = styled.div`
  label: column;
  background-color: #003153;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  flex-basis: 100px;
`;

const ColumnHeader = styled.div`
  label: column-header;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 2px solid #001f38;
  gap: 10px;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px;
`;

const ColumnBody = styled.div`
  label: column-body;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  gap: 10px;
  overflow-y: auto;
`;

const ColumnFooter = styled.div`
  label: column-footer;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 50px;
  flex: 0;
  padding: 15px;
`;
