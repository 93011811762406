import { atom } from "recoil";

import { Treatment } from "../../treatments";


export const protocolPlanAtom = atom<Treatment[]>({
  key: "protocolPlan",
  default:[]
});

export const earnedPointsAtom = atom<number>({
  key: "earnedPoints",
  default: 9,
});