import { GameState, gameStateAtom } from "../state/gameStateAtom";
import { useRecoilState } from "recoil";
import { gameStages } from "./GameStages";
import { useNavigate } from "react-router-dom";
import { saveGameState } from "../state/saveGameService";
import { useEffect } from "react";
import { DialogContext } from "../dialog/DialogContext";
import styled from "@emotion/styled";
import { useTourEventListeners } from "./tour/TourController";
import { useAirtableData } from "./useAirtableData";

const GamePlayContainer = styled.div`
  position: relative;
`;

declare global {
  interface Window {
    jumpTo: (stageId: string, stepId: string) => void;
  }
}

export const GamePlay = () => {
  const navigate = useNavigate();
  const [gameState, setGameState] = useRecoilState(gameStateAtom);
  const currentSavedStage = gameState.stages.find(
    (s) => s.id === gameState.currentStage
  );
  const currentStage = gameStages.find((s) => s.id === gameState.currentStage);
  const currentStep = currentStage?.steps[currentSavedStage?.currentStep ?? 0];
  console.info("GamePlay", gameState.currentStage, currentStep?.id);
  useAirtableData();
  useTourEventListeners();

  const onComplete = (
    context?: DialogContext,
    dialogLog?: string[],
    targetNextStep?: string
  ) => {
    if (!currentStage) {
      console.error("No current stage", gameState.currentStage);
      return;
    }
    if (!currentSavedStage) {
      console.error("No current saved stage", gameState.currentStage);
      return;
    }

    context = structuredClone(context);

    const thisStepId = currentStep?.id;

    const nextStep = targetNextStep
      ? currentStage?.steps.findIndex((step) => step.id === targetNextStep)
      : currentSavedStage?.currentStep + 1;

    context && (context.DialogFlags = {}); // Reset dialog flags each dialog
    if (nextStep < currentStage?.steps.length) {
      // Move to the next step within the current stage
      // Update the game state to reflect the new step
      // This keeps track of progress within a stage
      const newGameState = {
        ...gameState,
        dialogContext: context || gameState.dialogContext,
        currentStage: gameState.currentStage,
        stages: gameState.stages.map((s, index) =>
          s.id === currentStage.id ? { ...s, currentStep: nextStep } : s
        ),
        dialogLog: {
          ...(gameState.dialogLog ?? {}),
          [currentStep?.id || "unknown"]: dialogLog ?? [],
        },
        timestamps: [
          ...(gameState.timestamps || []),
          { id: thisStepId + "-completed", timestamp: Date.now() },
        ],
      };

      
      saveGameState(newGameState).then(() => {
        setGameState(newGameState);
      });
    } else {
      // The stage is complete, head back to the menu
      const newGameState = setStageCompleted(gameState.currentStage)({
        ...gameState,
        dialogLog: {
          ...(gameState.dialogLog ?? {}),
          [currentStep?.id || "unknown"]: dialogLog ?? [],
        },
        dialogContext: context || gameState.dialogContext,
        timestamps: [
          ...(gameState.timestamps || []),
          { id: thisStepId + "-completed", timestamp: Date.now() },
        ],
      });
      setGameState(newGameState);
      saveGameState(newGameState)
        .then(() => {
          navigate("/menu");
        })
        .catch((error) => {
          console.error("Error saving game state", error);
        });
    }
  };

  const jumpTo = (stageId: string, stepId: string) => {
    const targetStage = gameStages.find((s) => s.id === stageId);
    if (!targetStage) {
      console.error("Invalid stage ID", stageId);
      return;
    }

    const targetStepIndex = targetStage.steps.findIndex((s) => s.id === stepId);
    if (targetStepIndex === -1) {
      console.error("Invalid step ID", stepId);
      return;
    }

    const newGameState = {
      ...gameState,
      currentStage: stageId,
      stages: gameState.stages.map((s) =>
        s.id === stageId ? { ...s, currentStep: targetStepIndex } : s
      ),
    };

    saveGameState(newGameState).then(() => {
      setGameState(newGameState);
    });
  };

  useEffect(() => {
    window.jumpTo = jumpTo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState]);

  if (!currentStep) {
    return <div>No Step</div>;
  }

  return (
    <GamePlayContainer>
      {currentStep.component(onComplete, gameState.dialogContext)}
    </GamePlayContainer>
  );
};

const setStageCompleted = (stageId: string) => (gameState: GameState) => {
  const currentStageIndex = gameState.stages.findIndex((s) => s.id === stageId);
  const nextStageIndex = currentStageIndex + 1;

  return {
    ...gameState,
    stages: gameState.stages.map((s, idx) => ({
      ...s,
      completed: s.id === stageId,
      locked: idx > nextStageIndex,
    })),
  };
};
